import React from "react";
import { FullPanelImage } from "./FullPanelImage";
import { NumberedSection } from "./NumberedSection";
import { ImageGrid } from "./ImageGrid";
import { PullQuote } from "./PullQuote";
import { CarouselBlock } from "./CarouselBlock";
import { Related } from "./Related";

export const PageBlocks = ({ blocks }) => {
  if (!blocks || !blocks.length) return null;
  return blocks?.map((block, index) => {
    switch (block.fieldGroupName) {
      case "CaseStudy_Pageblocks_Blocks_FullPanelVideoOrImage":
        return <FullPanelImage key={index} block={block} />;
      case "CaseStudy_Pageblocks_Blocks_NumberedSection":
        return <NumberedSection key={index} block={block} />;
      case "CaseStudy_Pageblocks_Blocks_ImageGrid":
        return <ImageGrid key={index} block={block} />;
      case "CaseStudy_Pageblocks_Blocks_PullQuote":
        return <PullQuote key={index} block={block} />;
      case "CaseStudy_Pageblocks_Blocks_Carousel":
        return <CarouselBlock key={index} block={block} />;
      case "CaseStudy_Pageblocks_Blocks_RelatedContent":
        return <Related key={index} block={block} />;
      default:
        return null;
    }
  });
};
