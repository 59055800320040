import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Box, Grid } from "grommet";
import { useResponsive } from "../../hooks";

export const ImageGrid = ({ block: { images } }) => {
  const { isMobile, isDesktop, themeSizes } = useResponsive();

  return (
    <Box
      pad={{ vertical: "xlarge" }}
      flex="grow"
      width={{ max: "maxWidth", width: `calc(100% - ${themeSizes.large})` }}>
      <Grid
        fill="horizontal"
        gap={isMobile ? "medium" : "large"}
        columns={{
          count: isMobile ? 1 : 2,
          size: "auto",
        }}>
        {images.map((image, index) => {
          const gatsbyImage = getImage(image?.localFile);

          return (
            <Box
              key={index}
              pad={{ bottom: "133%" }}
              width="100%"
              style={{ position: "relative" }}>
              {gatsbyImage && (
                <GatsbyImage
                  image={gatsbyImage}
                  alt={image?.alt || ""}
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                  }}
                  imgStyle={{ objectFit: "cover" }}
                />
              )}
            </Box>
          );
        })}
      </Grid>
    </Box>
  );
};