import React from "react";
import { Heading, Box } from "grommet";

export const Team = ({ team }) => {
  if (!team || !team?.length) return null;
  return (
    <Box fill="horizontal">
      <Heading level={3} margin={{ vertical: "medium" }}>
        The Team
      </Heading>
      {team?.map((teamMember, index) => {
        return (
          <Box
            direction="row"
            gap="large"
            border={{ side: "bottom", size: "xsmall", color: "brand" }}
          >
            <Box flex="grow">
              <Heading level={4} margin={{ vertical: "xsmall" }}>
                {teamMember.position}
              </Heading>
            </Box>
            <Box flex="grow" align="end">
              <Heading level={4} margin={{ vertical: "xsmall" }}>
                {teamMember.name}
              </Heading>
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};
