import React, { useContext } from "react";
import parse, { domToReact } from "html-react-parser";
import { Heading, Box, Anchor, Paragraph } from "grommet";
import { useResponsive } from "../../hooks";

import SymbolHealth from "../../assets/svgs/healthSymbol.svg";
import SymbolEducation from "../../assets/svgs/educationSymbol.svg";
import SymbolCommercial from "../../assets/svgs/commercialSymbol.svg";
import SymbolFacilities from "../../assets/svgs/facilitiesSymbol.svg";


String.prototype.lpad = function (padString, length) {
  var str = this;
  while (str.length < length) str = padString + str;
  return str;
};

const SectionList = ({ blocks }) => {
  const scrollToSection = (e, section) => {
    e.preventDefault();
    const titleElement = document.getElementById(`section${section}`);
    titleElement.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <Box justify="between" direction="row">
      {blocks?.blocks?.map((item, index) => {
        if (
          item.fieldGroupName === "CaseStudy_Pageblocks_Blocks_NumberedSection"
        ) {
          return (
            <Box flex="shrink" key={index} align="center">
              <Anchor href="#" onClick={(e) => scrollToSection(e, item.number)}>
                <Heading
                  level={4}
                  size="small"
                  margin="none"
                  truncate={false}
                  // style={{ textAlign: "center" }}
                >
                  {`${item?.number?.toString().lpad("0", 2)} ${item.title}`}
                </Heading>
              </Anchor>
            </Box>
          );
        }
      })}
    </Box>
  );
};
const Symbols = {
  Health: <SymbolHealth width="100%" />,
  Education: <SymbolEducation width="100%" />,
  Commercial: <SymbolCommercial width="100%" />,
  Facilities: <SymbolFacilities width="100%" />,
};

export const TitleBlock = ({
  post,
  title,
  content,
  keyInformation,
  category,
}) => {
  const {
    isMobile,
    isDesktop,
    themeSizes,
    isSmallMobile,
    size,
  } = useResponsive();

  return (
    <Box
      direction={isMobile ? "row" : "column"}
      pad={{ vertical: "large" }}
      flex={"grow"}
      style={{ overflow: "hidden" }}
      height={{ min: "100vh" }}
      width={{ max: "maxWidth", width: `calc(100% - ${themeSizes.large})` }}
    >
      {/* <Meter
        style={{ position: "fixed", top: 80, right: 20, zIndex: 10000 }}
        type="circle"
        color="black"
        size="40px"
        thickness="2px"
        value={value}
      ></Meter> */}

      <Box
        fill="horizontal"
        flex="grow"
        direction={isDesktop ? "row" : "column"}
        gap={size}
        pad={isMobile ? { top: "xlarge" } : null}
      >
        <Box
          // width={{ max: isMobile ? "100px" : null }}
          flex={isMobile ? "shrink" : "grow"}
          width={isMobile ? "25%" : "30%"}
          style={{
            position: "relative",
            zIndex: 0,
          }}
        >
          {Symbols[category]}

          {isDesktop && (
            <Box
              fill
              justify="between"
              style={{ position: "absolute", left: 0, top: 0, zIndex: 1 }}
            >
              <Box>
                <Heading
                  level={4}
                  size="small"
                  margin="none"
                >{`Sector - ${keyInformation.sector}`}</Heading>
                <Heading
                  level={4}
                  size="small"
                  margin="none"
                >{`Scope - ${keyInformation.scope}`}</Heading>
              </Box>
              <Box>
                <Heading
                  level={4}
                  size="small"
                  margin="none"
                >{`Location - ${keyInformation.location}`}</Heading>
                <Heading
                  level={4}
                  size="small"
                  margin="none"
                >{`Duration - ${keyInformation.duration}`}</Heading>
              </Box>
              <Box>
                <Heading
                  level={4}
                  size="small"
                  margin="none"
                >{`Year - ${keyInformation.year}`}</Heading>
                <Heading
                  level={4}
                  size="small"
                  margin="none"
                >{`Cost - ${keyInformation.cost}`}</Heading>
              </Box>
            </Box>
          )}
        </Box>

        <Box
          flex="grow"
          style={{ position: "relative", zIndex: 1 }}
          justify="between"
          pad={{ top: "medium" }}
        >
          <Box width={{ max: "700px" }}>
            <Heading
              fill
              level={1}
              size={isSmallMobile ? "medium" : "medium"}
              margin="none"
            >
              {title}
            </Heading>
            <Box flex="grow">
              {parse(content, {
                replace: ({ name, children }) => {
                  if (name === "p") {
                    return <Paragraph fill>{domToReact(children)}</Paragraph>;
                  }
                },
              })}
            </Box>
          </Box>

          {isDesktop && <SectionList blocks={post.pageBlocks} />}
        </Box>
      </Box>
    </Box>
  );
};
