import React, { useEffect } from "react";
import { Link, graphql } from "gatsby";

import Layout from "../components/layout";
import Seo from "gatsby-plugin-wpgraphql-seo";
import { Box } from "grommet";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Typist from "react-typist";
import { PageBlocks } from "./Blocks/PageBlocks";
import { TitleBlock } from "../components/Blocks/TitleBlock";
import { CreditBlock } from "./Blocks/CreditBlock";

const BlogPostTemplate = ({
  data: { wpCaseStudy, previous, next, post },
  location,
}) => {
  return (
    <Layout isProjectPage location={location} previous={previous} next={next}>
      <Seo post={wpCaseStudy} />
      <Box background="background" fill="horizontal" align="center">
        <TitleBlock
          post={post}
          category={post?.caseStudyCategories?.nodes[0]?.name || null}
          title={post.title}
          keyInformation={post.keyInformation}
          content={post.content}
        />
        <PageBlocks blocks={post.pageBlocks.blocks} />
        <CreditBlock
          similarProjects={post.similarProjects.project}
          projectDetails={post.projectDetails}
        />
      </Box>
    </Layout>
  );
};

export default BlogPostTemplate;

export const pageQuery = graphql`
  query CaseStudiesById(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    post: wpCaseStudy(id: { eq: $id }) {
      id
      content
      title
      seo {
        title
        metaDesc
        opengraphTitle
        opengraphDescription
        opengraphImage {
          altText
          sourceUrl
          srcSet
        }
        twitterTitle
        twitterDescription
        twitterImage {
          altText
          sourceUrl
          srcSet
        }
        canonical
        metaRobotsNoindex
        metaRobotsNofollow
      }
      caseStudyCategories {
        nodes {
          name
        }
      }
      date(formatString: "MMMM DD, YYYY")
      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, quality: 75)
            }
          }
        }
      }
      keyInformation {
        location
        year
        sector
        scope
        fieldGroupName
        duration
        cost
      }
      projectDetails {
        fieldGroupName
        status
        teamMembers {
          fieldGroupName
          name
          position
        }
      }
      similarProjects {
        fieldGroupName
        project {
          ... on WpCaseStudy {
            id
            title
            uri
            featuredImage {
              node {
                localFile {
                  childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH, quality: 75)
                  }
                }
              }
            }
          }
        }
      }
      pageBlocks {
        blocks {
          ... on WpCaseStudy_Pageblocks_Blocks_NumberedSection {
            body
            fieldGroupName
            title
            number
            bulletPoints {
              text
            }
          }
          ... on WpCaseStudy_Pageblocks_Blocks_PullQuote {
            fieldGroupName
            name
            position
            quote
          }
          ... on WpCaseStudy_Pageblocks_Blocks_ImageGrid {
            fieldGroupName
            images {
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH, quality: 75)
                }
              }
            }
          }
          ... on WpCaseStudy_Pageblocks_Blocks_RelatedContent {
            fieldGroupName
            title
            content {
              ... on WpCaseStudy {
                id
              }
              ... on WpMediaItem {
                id
              }
              ... on WpPage {
                id
              }
              ... on WpPost {
                id
              }
            }
          }
          ... on WpCaseStudy_Pageblocks_Blocks_Carousel {
            fieldGroupName
            images {
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH, quality: 75)
                }
              }
            }
          }
          ... on WpCaseStudy_Pageblocks_Blocks_FullPanelVideoOrImage {
            contentType
            fieldGroupName
            vimeoId
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH, quality: 75)
                }
              }
            }
          }
        }
      }
    }

    previous: wpCaseStudy(id: { eq: $previousPostId }) {
      uri
      title
    }

    next: wpCaseStudy(id: { eq: $nextPostId }) {
      uri
      title
    }
  }
`;
