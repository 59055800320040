import React, { useState, useEffect, useRef } from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Box } from "grommet";
import Slider from "react-slick";
import styled from "styled-components";
import Arrow from "../../assets/svgs/arrow-more.svg";

const Cursor = styled.div.attrs((props) => ({
  style: {
    opacity: `${props.isVisible ? 1 : 0}`,
    top: `${props.y}px`,
    left: `${props.x}px`,
  },
}))`
    position: absolute;
    width: 60px;
    height: 60px;
    transform: translate(-50%, -50%);
    background: ${(props) => props.background};
    z-index: 999;
    pointer-events: none;
`;

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  const containerRef = useRef();
  const [coords, setCoords] = useState({ x: 0, y: 0 });
  const [isVisible, setIsVisible] = useState(false);

  function onMousemove(event) {
    const x = event.offsetX; // position x relative to div
    const y = event.offsetY; // position y relative to div
    setCoords({ x, y });
  }

  useEffect(() => {
    containerRef.current.addEventListener("mousemove", onMousemove, false);
    return () =>
      containerRef?.current?.removeEventListener("mousemove", onMousemove);
  }, []);

  return (
    <div
      onMouseEnter={() => setIsVisible(true)}
      onMouseLeave={() => setIsVisible(false)}
      ref={containerRef}
      className={className}
      style={{
        cursor: "none",
        ...style,
        display: "block",
        height: "90%",
        width: "10%",
        zIndex: 1000,
      }}
      onClick={onClick}>
      <Cursor x={coords.x} y={coords.y} isVisible={isVisible}>
        <Arrow
          style={{ height: "100%", width: "100%", transform: "rotate(0deg)" }}
        />
      </Cursor>
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  const containerRef = useRef();
  const [coords, setCoords] = useState({ x: 0, y: 0 });
  const [isVisible, setIsVisible] = useState(false);

  function onMousemove(event) {
    const x = event.offsetX; // position x relative to div
    const y = event.offsetY; // position y relative to div
    setCoords({ x, y });
  }

  useEffect(() => {
    containerRef.current.addEventListener("mousemove", onMousemove, false);
    return () =>
      containerRef?.current?.removeEventListener("mousemove", onMousemove);
  }, []);

  return (
    <div
      onMouseEnter={() => setIsVisible(true)}
      onMouseLeave={() => setIsVisible(false)}
      ref={containerRef}
      className={className}
      style={{
        cursor: "none",
        ...style,
        display: "block",
        height: "90%",
        width: "10%",
        zIndex: 1000,
      }}
      onClick={onClick}>
      <Cursor x={coords.x} y={coords.y} isVisible={isVisible}>
        <Arrow
          style={{ height: "100%", width: "100%", transform: "rotate(180deg)" }}
        />
      </Cursor>
    </div>
  );
}

export const CarouselBlock = ({ block: { images } }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    variableWidth: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  return (
    <Box
      background="background"
      width={{ max: "maxWidth" }}
      pad={{ vertical: "large" }}
      flex="grow"
      fill="horizontal"
      style={{overflow: "hidden"}}
    >
      <Box
        className="flex-slider"
        height="80vh"
        width="100%"
        flex={false}
        fill="horizontal"
        style={{
          position: "relative",
        }}
      >
        <Slider {...settings}>
          {images?.map((item, index) => {
            const image = getImage(item?.localFile);
            const aspectRatio = image.width / image.height;

            return (
              <div key={index}>
                <div
                  style={{
                    width: `calc(80vh * ${aspectRatio})`,
                    height: "80vh",
                  }}
                >
                  <GatsbyImage
                    image={image}
                    alt={item?.alt || ""}
                    style={{ height: "100%", width: "100%" }}
                    imgStyle={{ objectFit: "cover" }}
                  />
                </div>
              </div>
            );
          })}
        </Slider>
      </Box>
    </Box>
  );
};