import React from "react";
import { Heading, Paragraph, Box, Grid } from "grommet";
import { useResponsive } from "../../hooks";

String.prototype.lpad = function (padString, length) {
  var str = this;
  while (str.length < length) str = padString + str;
  return str;
};

export const NumberedSection = ({
  block: { number, title, body, bulletPoints },
}) => {
  const { isMobile, isDesktop, themeSizes, isSmallMobile } = useResponsive();

  const renderContent = () => (
    <Box>
      <Paragraph color="brand" fill>
        {body}
      </Paragraph>
      <Box fill="horizontal" flex>
        {bulletPoints?.length && (
          <ul
            style={{
              columns: isMobile ? 1 : 2,
              columnGap: 50,
              padding: "0 0 0 20px",
            }}>
            {bulletPoints?.map((bullet, index) => {
              return (
                <li key={index}>
                  <Paragraph margin={{ vertical: "none", bottom: "10px" }}>
                    {bullet.text}
                  </Paragraph>
                </li>
              );
            })}
          </ul>
        )}
      </Box>
    </Box>
  );
  return (
    <Box
      id={`section${number}`}
      width={{ max: "maxWidth", width: `calc(100% - ${themeSizes.large})` }}
      background="background"
      direction="column"
      align="top"
      justify="center"
      pad={{ top: "xlarge" }}
      flex="grow">
      <Box
        direction={isSmallMobile ? "column" : "row"}
        align={isMobile ? (isSmallMobile ? "start" : "end") : "start"}
        gap="large">
        <Box flex basis={"1/3"}>
          <Heading size="xlarge" level={2} margin="none">
            {number?.toString().lpad("0", 2)}
          </Heading>
        </Box>
        <Box flex basis={"2/3"} align={isMobile ? "end" : "start"}>
          <Heading
            level={3}
            size="large"
            color="brand"
            margin={{
              vertical: "none",
              left: "none",
              right: isMobile ? "xlarge" : "none",
            }}>
            {title}
          </Heading>
          {isDesktop && renderContent()}
        </Box>
      </Box>

      {isMobile && renderContent()}
    </Box>
  );
};
