import React from "react";
import { Heading, Box } from "grommet";

export const PullQuote = ({ block: { quote, name, position } }) => {
  return (
    <Box
      background="background"
      pad={{ vertical: "large", left: "26%" }}
      align="end"
      width={{ max: "maxWidth" }}
    >
      <Box align="start">
        <Heading level={3} size="xlarge" className="bold">
          {quote}
        </Heading>
        <Heading margin="none" level={4}>
          {name}
        </Heading>
        <Heading margin="none" level={4}>
          {position}
        </Heading>
      </Box>
    </Box>
  );
};
