import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Box } from "grommet";
import { useResponsive } from "../../hooks";
import { useVideojs } from "react-videojs-hook";

const Video = ({ video }) => {
  const { vjsId, vjsRef, vjsClassName } = useVideojs({
    src: video,
    controls: false,
    autoplay: true,
    responsive: true,
    bigPlayButtonCentered: true,
    fluid: true,
    muted: true,
    loop: true,
  });

  return (
    <video
      height="100%"
      ref={vjsRef}
      id={vjsId}
      className={vjsClassName}></video>
  );
};

export const FullPanelImage = ({ block }) => {
  const { isMobile, isDesktop, themeSizes } = useResponsive();
  const { contentType, vimeoId } = block;

  const image = block.image?.localFile ? getImage(block.image.localFile) : null;

  return (
    <Box
      fill="horizontal"
      height={isMobile ? "60vh" : "100vh"}
      style={{ position: "relative", overflow: "hidden" }}>
      {contentType === "video" ? (
        <Video video={vimeoId} />
      ) : (
        image && (
          <GatsbyImage
            image={image}
            alt={block.image?.alt || ""}
            style={{
              height: "100%",
              width: "100%",
              position: "absolute",
              top: 0,
            }}
            imgStyle={{ objectFit: "cover" }}
          />
        )
      )}
    </Box>
  );
};