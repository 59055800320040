import React from "react";
import { Box } from "grommet";



export const Related = ({ block }) => {
  return (
    <Box>
      <p>Related</p>
    </Box>
  );
};
