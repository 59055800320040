import React from "react";
import { Box } from "grommet";
import { Team } from "./Team";
import { SimilarProjects } from "./SimilarProjects";
import { ContactDetails } from "./ContactDetails";
import { useResponsive } from "../../hooks";

export const CreditBlock = ({
  similarProjects,
  projectDetails: { teamMembers },
}) => {
  const { isMobile, isDesktop, themeSizes, size } = useResponsive();

  return (
    <Box
      pad={{ vertical: "xlarge" }}
      direction={size === "medium" ? "column-reverse" : "row"}
      gap="large"
      flex="grow"
      width={{ max: "maxWidth", width: `calc(100% - ${themeSizes.large})` }}
    >
      {isDesktop && (
        <Box
          basis="1/3"
          direction={size === "medium" ? "row" : "column"}
          align="start"
          justify="start"
          gap={!teamMembers || !teamMembers?.length ? "medium" : "large"}
        >
          <Team team={teamMembers} />
          <ContactDetails team={!teamMembers || !teamMembers?.length} />
        </Box>
      )}
      <Box flex>
        <SimilarProjects projects={similarProjects} />
        {isMobile && (
          <Box
            margin={{
              top: !teamMembers || !teamMembers?.length ? "large" : "large",
            }}
            gap={!teamMembers || !teamMembers?.length ? "none" : "large"}
          >
            <Team team={teamMembers} />
            <ContactDetails team={teamMembers} />
          </Box>
        )}
      </Box>
    </Box>
  );
};
