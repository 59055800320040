import React, { useRef, useState, useEffect } from "react";
import { Heading, Paragraph, Box } from "grommet";
import { useResponsive } from "../../hooks";
import LinkArrow from "../../assets/svgs/link-arrow.svg";
import { gsap } from "gsap";
import { navigate } from "gatsby";

const Category = ({ title = "", position = 50, maxWidth = 400 }) => {
  const arrowRef = useRef(null);
  const [hover, setHover] = useState(false);

  useEffect(() => {
    hover &&
      gsap.to(arrowRef.current, {
        right: 0,
        duration: 0.25,
      });

    !hover &&
      gsap.to(arrowRef.current, {
        right: position,
        duration: 0.25,
      });
  }, [hover]);

  return (
    <Box
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={() => window.open(`mailto:enquiries@bauvill.co.uk`)}
      flex="grow"
      justify="center"
      fill="horizontal"
      style={{ overflow: "hidden" }}
      direction="column"
    >
      <Box
        flex={false}
        fill="horizontal"
        width={{ max: `${maxWidth}px` }}
        style={{
          overflow: "hidden",
          position: "relative",
          height: 100,
        }}
      >
        <Box style={{ position: "relative" }} fill="horizontal">
          <Paragraph style={{ position: "absolute", top: 15 }} color="brand">
            {title}
          </Paragraph>
          <Box
            ref={arrowRef}
            height="40px"
            width="150%"
            flex={false}
            align="end"
            style={{ position: "relative", top: 45, right: position }}
          >
            <LinkArrow stroke="#06303b" color={"#06303b"} style={{ width: "200%" }} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export const ContactDetails = ({
  team,
  position = 50,
  maxWidth = 400,
}) => {
  const { isMobile, isDesktop, themeSizes, size } = useResponsive();
  const topSpacing = !team || !team?.length ? false : true;

  return (
    <Box
      margin={{
        top: size === "medium" ? "none" : topSpacing ? "large" : "none",
      }}
    >
      <Heading
        margin={{ bottom: "medium", top: topSpacing ? "medium" : "none" }}
        level={3}
      >
        Contact
      </Heading>
      <Paragraph
        margin="none"
        color="brand"
        style={{ maxWidth: isMobile ? "none" : "250px" }}
      >
        Contact us today. Our team is only too happy to answer all your
        questions
      </Paragraph>

      <Category title="enquiries@bauvill.co.uk" position={20} />
    </Box>
  );
};
