import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Heading, Box, Grid, Anchor } from "grommet";
import { useResponsive } from "../../hooks";
import ArrowMore from "../../assets/svgs/arrow-more.svg";

export const SimilarProjects = ({ projects }) => {
  const {
    isMobile,
    isDesktop,
    themeSizes,
    isSmallMobile,
    size,
  } = useResponsive();

  return (
    <Box flex="grow">
      <Heading margin={{ vertical: "medium" }} level={3}>
        Similar Projects
      </Heading>
      <Grid
        columns={{ count: isSmallMobile ? 1 : 2, size: "auto" }}
        gap="medium">
        {projects?.map((item, index) => {
          const image = getImage(item?.featuredImage?.node?.localFile);

          return (
            <Box key={index}>
              <Box
                height="0"
                pad={{ bottom: isMobile ? "125%" : "75%" }}
                width="100%"
                style={{ position: "relative" }}>
                <GatsbyImage
                  image={image}
                  alt={item?.featuredImage?.alt || ""}
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                  }}
                  imgStyle={{
                    objectFit: "cover",
                  }}
                />
              </Box>
              <Box
                flex="grow"
                direction={"column"}
                justify={"between"}
                align={isMobile ? "start" : "center"}>
                <Box
                  fill="horizontal"
                  flex="grow"
                  border={{ side: "bottom", size: "small", color: "brand" }}>
                  <Heading
                    level={4}
                    size="small"
                    margin={{ vertical: isMobile ? "small" : "xsmall" }}>
                    {item.title}
                  </Heading>
                </Box>
                <Box
                  fill="horizontal"
                  flex="grow"
                  align="center"
                  direction="row"
                  justify="between">
                  <Anchor href={item.uri} size="small">
                    <Heading
                      level={4}
                      size="small"
                      margin={{ vertical: isMobile ? "small" : "xsmall" }}>
                      See Project
                    </Heading>
                  </Anchor>
                  <Anchor href={item.uri} size="small">
                    <Box margin={{ vertical: "xsmall" }}>
                      <ArrowMore
                        height="30px"
                        width="30px"
                        style={{
                          position: "relative",
                          top: 2,
                        }}
                      />
                    </Box>
                  </Anchor>
                </Box>
              </Box>
            </Box>
          );
        })}
      </Grid>
    </Box>
  );
};